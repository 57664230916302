<template>
  <div id="bcompany">
    <div class="bcompany-info">
      <el-image :src="logo" fit="cover"></el-image>
      <span>
        <p>公司代码：{{ code }}</p>
        <p>{{ name }}</p>
        <p>
          <i>{{ type }}</i>
          <router-link
            :to="{ path: '/control/companyc/info', query: { code: code } }"
            >详情</router-link
          >
        </p>
      </span>
      <div class="company-base-content-right">
        {{ { 1: "待审批", 2: "启用中", 3: "审批失败" }[this.status] }}
      </div>
    </div>
    <ul>
      <li>
        <p>累计发标总额</p>
        <p>{{ sumScale }} 万元</p>
      </li>
      <li>
        <p>年发标总额</p>
        <p>{{ yearScale }} 万元</p>
      </li>
      <li>
        <p>本月发标总额</p>
        <p>{{ monthScale }} 万元</p>
      </li>
      <li>
        <p>本月发标总数</p>
        <p>{{ cout }} 个</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "bcompany-index",
  data() {
    return {
      status: "",
      logo: "",
      code: "",
      name: "",
      type: "",
      sumScale: "",
      yearScale: "",
      monthScale: "",
      cout: "",

      typeList: {
        0: "集成类",
        1: "供货方",
        2: "招标方",
      },
    };
  },
  mounted() {
    this.getData();
    let user = JSON.parse(localStorage.user);
    if (user.type === 0) {
      this.$router.push("/a-company");
    } else if (user.type === 1) {
      this.$router.push("/control/companyb");
    } else if (user.type === 2) {
      //   this.$router.push("/control/companyc");
    }
  },
  methods: {
    getData() {
      this.$axios.get("/member/centerprisePage").then((res) => {
        if (res.state === 200) {
          const {
            logo,
            code,
            name,
            type,
            sumScale,
            yearScale,
            monthScale,
            cout,
          } = res.data;
          this.logo = logo;
          this.code = code;
          this.name = name;
          this.type = this.typeList[type];
          this.sumScale = sumScale ?? 0;
          this.yearScale = yearScale ?? 0;
          this.monthScale = monthScale ?? 0;
          this.cout = cout ?? 0;
          this.$axios
            .get(`/member/selectMemberDetail/${this.code}`)
            .then((res) => {
              this.status = res.data.status;
            });
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#bcompany {
  display: flex;
  flex-direction: column;
  margin: 0 0 114px;
  .bcompany-info {
    width: 664px;
    height: 188px;
    border: 1px solid #ededed;
    margin: 0 0 24px;
    padding: 32px;
    display: flex;
    ::v-deep.el-image {
      width: 124px;
    }
    position: relative;
    .company-base-content-right {
      position: absolute;
      width: 100px;
      height: 50px;
      font-size: 30px;
      text-align: right;
      right: 30px;
      top: 30px;
    }
    span {
      flex: 1;
      line-height: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0 0 0 32px;
      p {
        &:first-child {
          font-size: 20px;
          color: #333;
        }
        &:nth-child(2) {
          font-size: 28px;
          color: #333;
        }
        &:last-child {
          display: flex;
          justify-content: space-between;
          i {
            width: 104px;
            height: 32px;
            display: inline-block;
            line-height: 32px;
            text-align: center;
            font-size: 16px;
            color: #fff;
            background: #004375;
            font-style: normal;
          }
          a {
            font-size: 18px;
            color: #3883ff;
            text-decoration: none;
          }
        }
      }
    }
  }
  & > ul {
    height: 424px;
    border: 1px solid #ededed;
    padding: 20px 0 0 20px;
    li {
      width: 254px;
      height: 154px;
      border: 1px solid #ededed;
      list-style: none;
      margin: 12px;
      float: left;
      color: #333;
      padding: 52px 0 0;
      text-align: center;
      p {
        line-height: 1;
        &:first-child {
          font-size: 20px;
        }
        &:last-child {
          font-size: 30px;
          margin: 20px 0 0;
        }
      }
    }
  }
}
</style>
