<template>
  <div id="bid-info">
    <div class="crumbs">
      <router-link to="/control/companyb">首页</router-link>
      <label>></label>
      <router-link to="/control/bid">标地维护</router-link>
      <label>></label>
      <a>标地详情</a>
    </div>
    <div class="info-cont">
      <div class="info-cont__item">
        <label>标地图片</label>
        <el-image :src="info.poster" fit="cover"></el-image>
      </div>
      <div class="info-cont__item">
        <label>标地名称</label>
        <span>{{ info.name }}</span>
      </div>
      <div class="info-cont__item">
        <label>标地规模</label>
        <span>{{ info.scale | currency}} 万元</span>
      </div>
      <div class="info-cont__item">
        <label>标地介绍</label>
        <span>{{ info.intro }}</span>
      </div>
      <div class="info-cont__handle">
        <router-link to="/control/bid">返回</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bid-info",
  data() {
    return {
      id: this.$route.query.id,
      info: {
        poster: "",
        name: "",
        scale: "",
        intro: "",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$axios.get(`/subject/seachSubject/${this.id}`).then((res) => {
        if (res.state === 200) {
          const { poster, name, scale, intro } = res.data;
          this.info.poster = poster;
          this.info.name = name;
          this.info.scale = scale;
          this.info.intro = intro;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#bid-info {
  display: flex;
  flex-direction: column;
  margin: 0 0 114px;
  .info-cont {
    height: auto;
    border: 1px solid #ededed;
    display: flex;
    flex-direction: column;
    padding: 64px 0 0;
    &__item {
      height: auto;
      min-height: 34px;
      display: flex;
      color: #333;
      margin: 0 0 32px;
      label {
        width: 160px;
        line-height: 34px;
        font-size: 20px;
        padding: 0 0 0 64px;
      }
      span {
        width: 700px;
        font-size: 16px;
        line-height: 34px;
      }
      .el-image {
        width: 120px;
        height: 120px;
      }
    }
    &__handle {
      width: 456px;
      height: 36px;
      margin: 32px 0 64px;
      a {
        width: 150px;
        height: 36px;
        line-height: 34px;
        text-align: center;
        text-decoration: none;
        border: 1px solid #dadada;
        cursor: pointer;
        color: #333;
        display: block;
        float: right;
      }
    }
  }
}
</style>
