<template>
  <div id="bid-add">
    <div class="crumbs">
      <router-link to="/control/companyc">首页</router-link>
      <label>></label>
      <router-link to="/control/bid">标地维护</router-link>
      <label>></label>
      <a>新增标地</a>
    </div>
    <div class="add-cont">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="160px"
        hide-required-asterisk
      >
        <el-form-item label="标地图片" prop="posterF">
          <el-upload
            class="uploader-img"
            action=""
            :auto-upload="false"
            :show-file-list="false"
            :on-change="fileChange.bind(this, 'posterF')"
          >
            <img v-if="img.posterF" :src="img.posterF" />
            <i v-else class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="标地名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入标地名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="标地规模" prop="scale">
          <el-input v-model="ruleForm.scale" placeholder="请输入标地规模">
            <i slot="suffix"> 万元</i>
          </el-input>
        </el-form-item>
        <el-form-item label="标地介绍" prop="intro">
          <el-input
            v-model="ruleForm.intro"
            type="textarea"
            resize="none"
            placeholder="请输入标地介绍"
          ></el-input>
        </el-form-item>
        <el-form-item class="form-handle">
          <button type="button" @click="submitForm('ruleForm', 0)">
            保存草稿
          </button>
          <button type="button" @click="submitForm('ruleForm', 1)">提交</button>
          <router-link to="/control/bid">返回</router-link>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "product-add",
  data() {
    return {
      ruleForm: {
        posterF: "",
        name: "",
        scale: "",
        intro: "",
      },
      rules: {
        posterF: [
          {
            required: true,
            message: "请上传标地图片",
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入标地名称",
            trigger: "blur",
          },
        ],
        scale: [
          {
            required: true,
            message: "请输入标地规模",
            trigger: "blur",
          },
        ],
        intro: [
          {
            required: true,
            message: "请输入标地介绍",
            trigger: "blur",
          },
        ],
      },
      img: {
        posterF: "",
      },
    };
  },
  methods: {
    fileChange(key, file) {
      this.ruleForm[key] = file?.raw;
      this.img[key] = URL.createObjectURL(file?.raw);
    },
    submitForm(formName, v) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return false;
        const formData = new FormData();
        Object.keys(this.ruleForm).forEach((key) => {
          formData.set(key, this.ruleForm[key]);
        });
        formData.set("status", v);
        this.$axios.post("/subject/addSubject", formData).then((res) => {
          if (res.state === 200) {
            this.$message.success(res.message);
            this.$router.push("/control/bid");
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#bid-add {
  display: flex;
  flex-direction: column;
  margin: 0 0 114px;
  .add-cont {
    min-height: 764px;
    height: auto;
    border: 1px solid #ededed;
    padding: 64px 0 0;
    ::v-deep.el-form {
      display: flex;
      flex-direction: column;
      .el-form-item {
        margin-bottom: 32px;
        .el-form-item__label {
          padding: 0 0 0 64px;
          text-align: left;
          font-size: 20px;
          line-height: 34px;
          color: #333;
        }
        .el-form-item__content {
          line-height: 34px;
          .uploader-img {
            width: 150px;
            height: 150px;
            border: 1px solid #dadada;
            .el-upload {
              width: 148px;
              height: 148px;
              line-height: 148px;
              text-align: center;
              & > i {
                font-size: 20px;
                color: #999;
              }
              & > img {
                width: 148px;
                height: 148px;
                object-fit: cover;
              }
            }
          }
          & > .el-input {
            width: 224px;
            input {
              height: 34px;
              line-height: 34px;
            }
          }
          .el-textarea {
            textarea {
              width: 424px;
              height: 120px;
              border-radius: 0;
              font-family: "微软雅黑";
              &::placeholder {
                font-size: 14px;
              }
            }
          }
        }
        &.form-handle {
          height: 36px;
          margin: 32px 0 64px;
          .el-form-item__content {
            display: flex;
            justify-content: center;
            line-height: 36px;
            font-size: 18px;
            margin: 0 !important;
            & > a {
              width: 150px;
              height: 36px;
              line-height: 34px;
              text-align: center;
              text-decoration: none;
              border-width: 1px;
              border-style: solid;
              cursor: pointer;
              margin: 0 12px;
              border-color: #dadada;
              color: #333;
            }
            & > button {
              width: 150px;
              height: 36px;
              background: #3883ff;
              color: #fff;
              outline: none;
              border: none;
              cursor: pointer;
              margin: 0 12px;
            }
          }
        }
      }
    }
  }
}
</style>
