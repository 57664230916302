<template>
  <div id="ccompany-info">
    <div class="crumbs">
      <router-link to="/control/companyc">首页</router-link>
      <label>></label>
      <a>企业详情</a>
    </div>
    <div class="info-cont1">
      <div class="info-cont1__left">
        <el-image :src="logo" fit="cover"></el-image>
        <span>
          <p>{{ code }}</p>
          <p>{{ name }}</p>
          <p>
            <i>{{ type }}</i>
          </p>
        </span>
      </div>
      <div class="info-cont1__right">
        <p>会员到期日：</p>
        <p>2021-12-31 23:59:59</p>
        <p>
          <a href="###">立即续费</a>
        </p>
      </div>
    </div>
    <div class="info-cont2">
      <p>
        <em>手机号码</em>
        <label>{{ bindPhone }}</label>
      </p>
      <p>
        <em>公司资质</em>
        <label>高新企业: {{ tech }}</label>
      </p>
      <!-- <p class="tips">
				<i class="el-icon-question"></i>
				<em>您上次修改资料的申请，尚未审核，请不要重复提交。</em>
				<label>立即取消</label>
			</p> -->
      <!-- <p class="tips">
				<i class="el-icon-question"></i>
				<em>您上次修改资料的申请，未审核通过。</em>
				<label>查看原因</label>
			</p>
			<p class="tips">
				<i class="el-icon-question"></i>
				<em>您上次修改资料的申请，已审核通过。</em>
				<label>我知道了，下次不再显示</label>
			</p> -->
      <p>
        <router-link
          :to="{ path: '/control/companyc/edit', query: { code: code } }"
          >修改</router-link
        >
        <router-link to="/control/companyc">返回</router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ccompany-info",
  data() {
    return {
      logo: "",
      code: this.$route.query?.code ?? "",
      name: "",
      type: "",
      bindPhone: "",
      sysIntegration: "",
      cmmi: "",
      tech: "",

      typeList: {
        0: "集成类",
        1: "供货方",
        2: "招标方",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$axios.get(`/member/selectMemberDetail/${this.code}`).then((res) => {
        if (res.state === 200) {
          const {
            logo,
            code,
            name,
            type,
            bindPhone,
            sysIntegration,
            cmmi,
            tech,
          } = res.data;
          this.logo = logo;
          this.code = code;
          this.name = name;
          this.type = this.typeList[type];
          this.bindPhone = bindPhone;
          this.sysIntegration = sysIntegration ?? "";
          this.cmmi = cmmi ?? "";
          this.tech = tech ?? "";
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#ccompany-info {
  display: flex;
  flex-direction: column;
  margin: 0 0 114px;
  .info-cont1 {
    height: 188px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    margin: 0 0 24px;
    &__left {
      width: 664px;
      height: 188px;
      border: 1px solid #ededed;
      padding: 32px;
      display: flex;
      ::v-deep.el-image {
        width: 124px;
      }
      span {
        flex: 1;
        line-height: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 0 0 32px;
        p {
          &:first-child {
            font-size: 20px;
            color: #333;
          }
          &:nth-child(2) {
            font-size: 28px;
            color: #333;
          }
          &:last-child {
            display: flex;
            justify-content: space-between;
            i {
              width: 104px;
              height: 32px;
              display: inline-block;
              line-height: 32px;
              text-align: center;
              font-size: 16px;
              color: #fff;
              background: #004375;
              font-style: normal;
            }
            a {
              font-size: 18px;
              color: #3883ff;
              text-decoration: none;
            }
          }
        }
      }
    }
    &__right {
      width: 254px;
      height: 188px;
      border: 1px solid #ededed;
      padding: 30px;
      font-size: 18px;
      line-height: 1;
      color: #333;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        &:last-child {
          text-align: right;
          a {
            color: #3883ff;
            text-decoration: none;
          }
        }
      }
    }
  }
  .info-cont2 {
    height: 376px;
    flex-shrink: 0;
    border: 1px solid #ededed;
    padding: 30px 0 0;
    p {
      display: flex;
      font-size: 20px;
      color: #333;
      line-height: 1;
      margin: 32px 0 0;
      em {
        width: 176px;
        text-align: right;
        padding: 0 32px 0 0;
        font-style: normal;
      }
      &:last-child {
        display: flex;
        justify-content: center;
        a {
          width: 150px;
          height: 36px;
          display: inline-block;
          border-width: 1px;
          border-style: solid;
          text-align: center;
          line-height: 34px;
          font-size: 18px;
          text-decoration: none;
          margin: 0 12px;
          cursor: pointer;
          &:first-child {
            border-color: #3883ff;
            background: #3883ff;
            color: #fff;
            &.disabled {
              border-color: #c4c4c4;
              background: #c4c4c4;
            }
          }
          &:last-child {
            border-color: #dadada;
            color: #333;
          }
        }
      }
      &.tips {
        padding: 0 0 0 308px;
        font-size: 16px;
        line-height: 1;
        i {
          color: #fa6400;
          margin: 0 10px 0 0;
        }
        em {
          width: auto;
          padding: 0;
        }
        label {
          color: #3883ff;
          margin: 0 0 0 20px;
        }
      }
    }
  }
}
</style>
