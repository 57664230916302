<template>
  <div id="ccompany-edit">
    <div class="crumbs">
      <router-link to="/control/companyc">首页</router-link>
      <label>></label>
      <router-link :to="`/control/companyc/info?code=${ruleForm.code}`"
        >企业详情</router-link
      >
      <label>></label>
      <a>编辑</a>
    </div>
    <div class="edit-cont">
      <h2>编辑企业信息</h2>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="220px"
        hide-required-asterisk
      >
        <template v-if="step === 1">
          <p>请填下以下公司信息</p>
          <el-form-item label="企业Logo" prop="logoF">
            <el-upload
              class="uploader-img"
              action=""
              :auto-upload="false"
              :show-file-list="false"
              :on-change="fileChange.bind(this, 'logoF')"
            >
              <img v-if="img.logoF" :src="img.logoF" />
              <i v-else class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="公司名称" prop="name">
            <el-input
              v-model="ruleForm.name"
              placeholder="请填写公司名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司代码" prop="code">
            <el-input
              v-model="ruleForm.code"
              placeholder="请填写公司代码"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="bindPhone">
            <el-input
              v-model="ruleForm.bindPhone"
              placeholder="请填写手机号码"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item class="form-handle">
            <router-link :to="`/control/companyc/info?code=${ruleForm.code}`"
              >上一步</router-link
            >
            <button type="button" @click="stepChange(2)">下一步</button>
            <router-link :to="`/control/companyc/info?code=${ruleForm.code}`"
              >返回</router-link
            >
          </el-form-item>
        </template>
        <template v-if="step === 2">
          <p>请完善公司信息</p>
          <!-- <el-form-item label="经办人身份证" prop="idCardF">
            <el-upload
              class="uploader-img2"
              action=""
              :auto-upload="false"
              :show-file-list="false"
              :on-change="fileChange.bind(this, 'idCardF')"
            >
              <img v-if="img.idCardF" :src="img.idCardF" />
              <i v-else class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="" prop="idCardF2">
            <el-upload
              class="uploader-img2"
              action=""
              :auto-upload="false"
              :show-file-list="false"
              :on-change="fileChange.bind(this, 'idCardF2')"
            >
              <img v-if="img.idCardF2" :src="img.idCardF2" />
              <i v-else class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="授权文件" prop="certF">
            <el-upload
              class="uploader-img3"
              action=""
              :auto-upload="false"
              :show-file-list="false"
              :on-change="fileChange.bind(this, 'certF')"
            >
              <img v-if="img.certF" :src="img.certF" />
              <i v-else class="el-icon-plus"></i>
            </el-upload>
          </el-form-item> -->

          <el-form-item label="身份证正反面">
            <el-upload
              class="file-item"
              action=""
              :auto-upload="false"
              :on-change="file_Change.bind(this, 'idCardF')"
              :on-remove="file_Remove.bind(this, 'idCardF')"
              :limit="1"
              accept=".pdf"
            >
              <el-button slot="trigger" size="small" type="primary"
                >上传</el-button
              >
            </el-upload>
            <div v-if="form_data.idCardF.length > 0" class="show">
              <p
                @click="
                  handleClick('idCardF', '身份证正反面', form_data.idCardF)
                "
              >
                预览
              </p>
            </div>
          </el-form-item>
          <el-form-item label="公司授权委托书">
            <el-upload
              action=""
              class="file-item"
              :auto-upload="false"
              :on-change="file_Change.bind(this, 'certF')"
              :on-remove="file_Remove.bind(this, 'certF')"
              :limit="1"
              accept=".pdf"
            >
              <el-button slot="trigger" size="small" type="primary"
                >上传</el-button
              >
            </el-upload>
            <div v-if="this.form_data.certF.length > 0">
              <p
                class="show"
                @click="handleClick('certF', '公司授权委托书', form_data.certF)"
              >
                预览
              </p>
            </div>
          </el-form-item>
          <el-dialog
            :title="eldialogTitle"
            :visible.sync="isViewPdf20"
            :fullscreen="true"
          >
            <iframe
              :src="eldialogUrl"
              frameborder="0"
              style="width: 100%; height: 85vh"
            ></iframe>
          </el-dialog>
          <el-form-item class="form-handle">
            <a @click="stepChange(1)">上一步</a>
            <button type="button" @click="submitForm('ruleForm')">提交</button>
            <router-link :to="`/control/companyc/info?code=${ruleForm.code}`"
              >返回</router-link
            >
          </el-form-item>
        </template>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "ccompany-edit",
  data() {
    return {
      step: 1,
      isViewPdf20: false,
      eldialogTitle: "",
      eldialogUrl: "",
      ruleForm: {
        id: "",
        logoF: "",
        name: "",
        code: this.$route.query?.code ?? "",
        bindPhone: "",
        idCardF: "",
        // idCardF2: "",
        certF: "",
      },
      img: {
        logoF: "",
        idCardF: "",
        idCardF2: "",
        certF: "",
      },
      rules: {
        // logoF: [{
        // 	required: true, message: '请上传企业Logo', trigger: 'change',
        // }],
        name: [
          {
            required: true,
            message: "请填写公司名称",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请填写公司代码",
            trigger: "blur",
          },
        ],
        bindPhone: [
          {
            required: true,
            message: "请填写手机号码",
            trigger: "blur",
          },
        ],
        // idCardF: [{
        // 	required: true, message: '请上传身份证正面照', trigger: 'change',
        // }],
        // idCardF2: [{
        // 	required: true, message: '请上传身份证反面照', trigger: 'change',
        // }],
        // certF: [{
        // 	required: true, message: '请上传授权书', trigger: 'change',
        // }],
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$axios
        .get(`/member/selectMemberDetail/${this.ruleForm.code}`)
        .then((res) => {
          if (res.state === 200) {
            const {
              id,
              logo,
              code,
              name,
              bindPhone,
              id1,
            //   id2,
              certFile,
            } = res.data;
            this.ruleForm.id = id;
            this.ruleForm.code = code;
            this.ruleForm.name = name;
            this.ruleForm.bindPhone = bindPhone;
            this.img.logoF = logo;
            // this.img.idCardF = id1;
            // this.img.idCardF2 = id2;
            // this.img.certF = certFile;
            this.form_data = {
              idCardF: [res.data.id1],
              certF: [res.data.certFile],
              idCardFUrl: res.data.id1,
              certFUrl: res.data.certFile,
            };
          } else {
            this.$message.error(res.message);
          }
        });
    },
    stepChange(v) {
      this.step = v;
    },
    fileChange(key, file) {
      this.ruleForm[key] = file?.raw;
      this.img[key] = URL.createObjectURL(file?.raw);
    },
    file_Change(key, file, fileList) {
      //认证文件只能上传pdf文件
      // this.fileList = fileList;
      var urlKey = key + "Url";
      this.form_data[key] = [];
      this.form_data[urlKey] = "";
      var fileName = file.name;
      var fileNameIndex = fileName.lastIndexOf(".");
      var newfileName = fileName.substring(fileNameIndex, fileName.length);
      if (newfileName != ".pdf") {
        this.$message.error("上传文件只能是 PDF 格式!");
        fileList[0] = "";
      } else {
        let reader = new FileReader();
        reader.readAsDataURL(file.raw);
        let URL = window.URL || window.webkitURL;
        let url = URL.createObjectURL(file.raw);
        this.form_data[key] = [url];
        this.form_data[urlKey] = url;
      }
    },
    file_Remove(key, file, fileList) {
      var urlKey = key + "Url";
      this.form_data[key] = [];
      this.form_data[urlKey] = "";
    },
    handleClick(item, title, file) {
      //预览文件
      this.isViewPdf20 = false;
      let url = item + "Url";
      this.isViewPdf20 = true;
      this.eldialogTitle = title;
      this.eldialogUrl = this.form_data[url];
    },
    submitForm(formName) {
      if (this.form_data.idCardF.length) {
        this.ruleForm.idCardF = this.form_data.idCardF[0];
      }
      if (this.form_data.certF.length) {
        this.ruleForm.certF = this.form_data.certF[0];
      }
      this.$refs[formName].validate((valid) => {
        if (!valid) return false;
        const formData = new FormData();
        Object.keys(this.ruleForm).forEach((key) => {
          formData.set(key, this.ruleForm[key]);
        });
        this.$axios.post("/member/updataMember", formData).then((res) => {
          if (res.state === 200) {
            this.$message.success(res.message);
            this.$router.push("/control/companyc");
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#ccompany-edit {
  display: flex;
  flex-direction: column;
  margin: 0 0 114px;
  .edit-cont {
    min-height: 820px;
    height: auto;
    border: 1px solid #ededed;
    h2 {
      font-size: 28px;
      color: #333;
      line-height: 40px;
      margin: 64px 0;
      text-align: center;
      font-weight: normal;
    }
    ::v-deep.el-form {
      display: flex;
      flex-direction: column;
      & > p {
        font-size: 20px;
        line-height: 28px;
        color: #333;
        margin: 0 0 46px 64px;
      }
      .el-form-item {
        margin-bottom: 32px;
        .el-form-item__label {
          padding: 0 0 0 64px;
          text-align: left;
          font-size: 20px;
          line-height: 34px;
          color: #333;
        }
        .el-form-item__content {
          line-height: 34px;
          .uploader-img {
            width: 150px;
            height: 150px;
            border: 1px solid #dadada;
            .el-upload {
              width: 148px;
              height: 148px;
              line-height: 148px;
              text-align: center;
              & > i {
                font-size: 20px;
                color: #999;
              }
              & > img {
                width: 148px;
                height: 148px;
                object-fit: cover;
              }
            }
          }
          .uploader-img2 {
            width: 240px;
            height: 150px;
            border: 1px solid #dadada;
            .el-upload {
              width: 238px;
              height: 148px;
              line-height: 148px;
              text-align: center;
              & > i {
                font-size: 20px;
                color: #999;
              }
              & > img {
                width: 238px;
                height: 148px;
                object-fit: cover;
              }
            }
          }
          .uploader-img3 {
            width: 206px;
            height: 240px;
            border: 1px solid #dadada;
            .el-upload {
              width: 204px;
              height: 238px;
              line-height: 238px;
              text-align: center;
              & > i {
                font-size: 20px;
                color: #999;
              }
              & > img {
                width: 204px;
                height: 238px;
                object-fit: cover;
              }
            }
          }
          .el-input {
            width: 224px;
            input {
              height: 34px;
              line-height: 34px;
            }
          }
          .el-input__suffix-inner i {
            font-style: normal;
            padding: 0 10px;
          }
        }
        &.form-handle {
          height: 36px;
          margin: 32px 0 64px;
          .el-form-item__content {
            display: flex;
            justify-content: center;
            line-height: 36px;
            font-size: 18px;
            margin: 0 !important;
            & > a {
              width: 150px;
              height: 36px;
              line-height: 34px;
              text-align: center;
              text-decoration: none;
              border-width: 1px;
              border-style: solid;
              cursor: pointer;
              margin: 0 12px;
              &:nth-of-type(1) {
                border-color: #3883ff;
                background: #3883ff;
                color: #fff;
              }
              &:nth-of-type(2) {
                border-color: #dadada;
                color: #333;
              }
            }
            & > button {
              width: 150px;
              height: 36px;
              background: #3883ff;
              color: #fff;
              outline: none;
              border: none;
              cursor: pointer;
              margin: 0 12px;
            }
          }
        }
      }
    }
  }
}
.el-form-item {
  // display: flex;
  position: relative;
  // align-items: center;
  // justify-content: space-around;
  // width: 500px;

  .show {
    position: absolute;
    right: 390px;
    // height: ;
    top: 2px;
    width: 56px;
    height: 30px;
    text-align: center;
    line-height: 32px;
    color: #fff;
    font-size: 12px;
    background-color: #3883ff;
    cursor: pointer;
  }
  .file-item {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    .el-upload-list {
      width: 150px;
    }
    .el-upload {
      margin-left: 20px;
      .el-button {
        position: absolute;
        left: 200px;
      }
    }
  }
}
</style>
